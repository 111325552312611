<script>
  import { project } from "$lib/services/store";
  import { translations } from "$lib/data/translations";
  import { supabase } from "$lib/services/db";
  import Modal from "$lib/main/components/Modal.svelte";
  import Icons from "$lib/main/components/Icons.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";

  let showSearch = false;
  let searchTerm = "";
  let searchResults = [];
  let searchResultsReviews = null;
  let searchResultsSlots = null;
  let searchResultsArticles = null;

  $: {
    searchResultsReviews = searchResults.filter((obj) => {
      return obj.parent_id === $project.data.categoryPages.reviews.id ?? "";
    });
    searchResultsSlots = searchResults.filter((obj) => {
      return obj.parent_id === $project.data.categoryPages.slots.id ?? "";
    });
    searchResultsArticles = searchResults.filter((obj) => {
      return (
        (obj.parent_id != $project.data.categoryPages.reviews.id ?? "") &&
        (obj.parent_id != $project.data.categoryPages.slots.id ?? "")
      );
    });
  }

  const search = async () => {
    let { data } = await supabase
      .from("content")
      .select("slug, title, parent_id")
      .ilike("title", `%${searchTerm}%`)
      .eq("project_key", $project.key)
      .eq("is_published", true);
    searchResults = data;
  };

  const toggleSearchModal = () => {
    showSearch = !showSearch;

    if (showSearch) {
      // Timeout to make sure the modal is open before focusing the input, otherwise it won't work
      setTimeout(() => {
        document.getElementById("search-input").focus();
      }, 100);
    }
  };
</script>

<button class="search-site" aria-label="Open search bar for website" on:click={toggleSearchModal}>
  <Icons name="search" height="20px" width="20px" strokeWidth="3" color="#fff" />
</button>

<Modal bind:toggleModal={showSearch} removeHeader="True" addClass="search">
  <Input
    id="search-input"
    placeholder={`${translations["Search"][$project.settings.lang]} ${$project.name}`}
    bind:value={searchTerm}
    on:keyup={() => search()}
  />
  {#if searchTerm}
    <div class="search-result">
      <ul class="review">
        <h4><Translate text="Casinos" /></h4>
        {#each searchResultsReviews as result, i}
          {#if i < 10}
            {@const operator_lower = result.slug.split("/").slice(-1)}
            <li>
              <a href={result.slug}>
                <img
                  loading="lazy"
                  width="100px"
                  height="40px"
                  class={"_" + operator_lower}
                  src={$project.data?.imageFolders.operators + operator_lower + ".png?w=200&q=80&auto=format"}
                  alt={result.title}
                />
                <span>{result.title}</span>
              </a>
            </li>
          {/if}
        {/each}
      </ul>
      <ul class="slots">
        <h4>Slots</h4>
        {#each searchResultsSlots as result, i}
          {#if i < 10}
            {@const casino_game_lower = result.slug.split("/").slice(-1)}
            <li>
              <a href={result.slug} on:click={() => (showSearch = false)}>
                <img
                  loading="lazy"
                  width="60px"
                  height="40px"
                  src={$project.data?.imageFolders.casinoGames + casino_game_lower + ".jpg?w=120&h=80&q=80&auto=format"}
                  alt={result.title}
                />
                <span>{result.title}</span>
              </a>
            </li>
          {/if}
        {/each}
      </ul>
      <ul class="articles">
        <h4><Translate text="Articles" /> & <Translate text="News" lowercase /></h4>
        {#each searchResultsArticles as result, i}
          {#if i < 20}
            <li><a href={result.slug} on:click={() => (showSearch = false)}>{result.title}</a></li>
          {/if}
        {/each}
      </ul>
    </div>
  {/if}
</Modal>
